import * as s from './ArHomepageLogos.module.scss'

import React from 'react'
import LogoChaucer from 'src/assets/svg/logo-chaucer.svg'
import LogoConvex from 'src/assets/svg/logo-convex.svg'
import LogoLockton from 'src/assets/svg/logo-lockton.svg'
import LogoBms from 'src/assets/svg/logo-bms.svg'
import LogoAon from 'src/assets/svg/logo-aon.svg'
import LogoGallagher from 'src/assets/svg/logo-gallagher.svg'
import LogoArdonagh from 'src/assets/svg/logo-ardonagh.svg'
import LogoAxis from 'src/assets/svg/logo-axis.svg'
import PplLogoUrl from 'src/assets/images/ppl-logo.png'
import ApolloLogo from '../../assets/images/apollo-logo.png'
import { TArHomepageLogosProps } from './ArHomepageLogos.types'
import { ArContainer } from 'src/components/ArContainer'

export const ArHomepageLogos: React.FC<TArHomepageLogosProps> = () => {
  return (
    <ArContainer className={s.container}>
      <h6>TRUSTED BY some of THE WORLD’S BEST INSURANCE COMPANIES</h6>
      <div className={s.logos}>
        <div>
          <LogoAxis/>
        </div>
        <div>
          <LogoChaucer />
        </div>
        <div>
          <LogoConvex />
        </div>
        <div>
          <LogoAon />
        </div>
        <div>
          <LogoArdonagh />
        </div>
        <div>
          <LogoLockton />
        </div>
        <div className="w-32">
          <img
            src={ApolloLogo} alt="Apollo logo" className={s.apolloLogo} />
        </div>
        <div>
          <LogoBms />
        </div>
        <div>
          <img
            className={s.logo}
            src={PplLogoUrl}
            alt="ppl insurance logo"
          />
        </div>
        <div>
          <LogoGallagher />
        </div>
      </div>
    </ArContainer>
  )
}
