import * as s from './ArThreeFeaturedArticles.module.scss'

import { ArArticleTile } from 'src/components/ArArticleTile'
import { ArContainer } from 'src/components/ArContainer'
import { ROUTES } from 'src/config/routes'
import React from 'react'
import { TArThreeFeaturedArticlesProps } from './ArThreeFeaturedArticles.types'
import classNames from 'classnames'
import { ArButton } from 'src/components/ArButton'

export const ArThreeFeaturedArticles: React.FC<TArThreeFeaturedArticlesProps> = ({ title, articles, theme }) => {
  return (
    <ArContainer
      full
      className={classNames(s.container, {
        [s.themeWhite]: theme === 'white',
        [s.themeGray]: theme === 'gray',
      })}
      contentWrapperClassName={s.contentWrapper}>
      <h2>{title}</h2>
      <div className={s.articles}>
        {articles.map((item) => (
          <ArArticleTile key={item.node.id} theme={theme} data={item.node} />
        ))}
      </div>
      <a href={ROUTES.blog()}>
        <ArButton className={s.blogLink} theme="white-outlined" hideArrow>View our blog<span className="ml-1.5 text-red-400">→</span></ArButton>
      </a>
    </ArContainer>
  )
}
