/**
 *  TODO:
 *  - Email and Phone connected to GTM
 *  - Cookie bar
 */

import React, { useEffect, useState } from 'react'
import { graphql, navigate } from 'gatsby'

import { ArButton } from 'src/components/ArButton'
import { ArHomepageCustomerLove } from 'src/components/ArHomepageCustomerLove'
import { ArHomepageLogos } from 'src/components/ArHomepageLogos'
import { ArHomepagePoweringTiles } from 'src/components/ArHomepagePoweringTiles'
import { ArHomepageScreenshot } from 'src/components/ArHomepageScreenshot'
import { ArRichCtaWithBg } from 'src/components/ArRichCtaWithBg'
import { ArSeo } from 'src/components/ArSeo'
import { ArThreeFeaturedArticles } from 'src/components/ArThreeFeaturedArticles'
import { BlogItemsQueryQuery } from 'gatsby-graphql'
import HeaderVideo from 'src/assets/videos/20210909_ARTIFICIAL_HYBRID_LOOP_01_CAM-03_1080.mp4'
import HomepageFooterCtaBg from 'src/assets/images/HomepageFooterCtaBg.jpg'
import HomepageFooterCtaBgWebp from 'src/assets/images/HomepageFooterCtaBg.webp'
import HomepageHeroBackground from 'src/assets/images/HomepageHeroBackground.jpg'
import HomepageHeroBackgroundWebp from 'src/assets/images/HomepageHeroBackground.webp'
import { METRICS } from 'src/config/metrics'
import { ROUTES } from 'src/config/routes'
import { isWebpSupported } from 'src/utils/isWebpSupported'
import { ArHomepagePlatformSelect } from 'src/components/ArHomepagePlatformSelect/ArHomepagePlatformSelect'
import ArrowRight from 'src/assets/svg/arrow-right.svg'

const IndexPage = ({ data }: { data: BlogItemsQueryQuery }) => {
  const [footerCtaBgUrl, setFooterCtaBgUrl] = useState<string>()
  const [heroBackgroundUrl, setHeroBackgroundUrl] = useState<string>()

  useEffect(() => {
    setFooterCtaBgUrl(isWebpSupported() ? HomepageFooterCtaBgWebp : HomepageFooterCtaBg)
    setHeroBackgroundUrl(isWebpSupported() ? HomepageHeroBackgroundWebp : HomepageHeroBackground)
  }, [])

  return (
    <>
      <ArSeo route={ROUTES.homepage()} />
      <ArRichCtaWithBg
        className="md:-translate-y-8 pb-4 md:pb-16"
        classNameSubtitle="max-w-600 pt-6 pb-8"
        backgroundUrl={heroBackgroundUrl}
        backgroundVideoUrl={HeaderVideo}
        heights={{
          mobile: METRICS.HomepageHeroHeightMobile,
          desktop: METRICS.HomepageHeroHeightDesktop,
        }}
        title={['The foundations of modern specialty (re)insurance']}
        subtitle="Build your future placement and underwriting strategy with Artificial's pioneering technology."
        actionButtons={[
          {
            title: 'Get in touch',
            onClick: () => {
              navigate(ROUTES.contact())
            },
            theme: 'dark',
          },
        ]}
        additionTopElement={
          <ArButton
            className="my-14 md:mb-12 md:mt-0"
            theme="glow-red"
            onClick={() => {
              navigate(ROUTES.careers())
            }}>
            <span className="flex items-center"><strong>We’re hiring!</strong>&nbsp;Join the Artificial team <ArrowRight /></span>
          </ArButton>
        }
        entryAnimation
      />
      <ArHomepageScreenshot entryAnimation />
      <ArHomepagePoweringTiles />
      <ArHomepageLogos />
      <ArHomepagePlatformSelect />
      <ArHomepageCustomerLove />
      <ArThreeFeaturedArticles
        theme="white"
        title="Passionate about the future of insurance"
        articles={data.articles.edges}
      />
      <ArRichCtaWithBg
        backgroundUrl={footerCtaBgUrl}
        heights={{
          mobile: METRICS.FooterHeightMobile,
          desktop: METRICS.FooterHeightMobile,
        }}
        title={[`Want to learn more?`]}
        subtitle="Book a chat with us today to discover how our technology could help transform your specialty (re)insurance business."
        actionButtons={[
          {
            title: 'Contact us',
            onClick: () => {
              navigate(ROUTES.contact())
            },
            theme: 'dark',
          },
        ]}
        withDarkGradient
      />
    </>
  )
}

export const query = graphql`
  query BlogItemsQuery {
    articles: allContentfulPost(sort: { fields: [createdAt], order: DESC }, limit: 3) {
      edges {
        node {
          id
          slug
          title {
            title
          }
          description {
            description
          }
          author {
            id
            name
            profilePhoto {
              file {
                url
              }
            }
          }
          category {
            title
          }
          isPressRelease
          date
        }
      }
    }
  }
`

export default IndexPage
