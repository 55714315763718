import * as s from './ArHomepagePoweringTiles.module.scss'

import { TRichText, parseRichText } from 'src/utils/parseRichText'

import { ArContainer } from 'src/components/ArContainer'
import React from 'react'
import SvgDocumentSign from 'src/assets/svg/document-sign.svg'
import SvgCostBarChart from 'src/assets/svg/cost-bar-chart.svg'
import SvgTrianglesGraph from 'src/assets/svg/triangles-graph.svg'
import { TArHomepagePoweringTilesProps } from './ArHomepagePoweringTiles.types'

const TILES: {
  title: string
  subtitle: TRichText
  icon: any
}[] = [
  {
    title: 'Empower your team',
    subtitle: ['Unlock the full potential of your team by enabling them to focus entirely on broking or underwriting.'],
    icon: <SvgDocumentSign />,
  },
  {
    title: 'Improve performance',
    subtitle: ['Make smarter, more informed decisions with access to more high-quality, actionable data.'],
    icon: <SvgTrianglesGraph />,

  },
  {
    title: 'Reduce costs',
    subtitle: ['Simplify the complexities of the specialty market with automation, reducing operational overhead.'],
    icon: <SvgCostBarChart />,
  },
]

export const ArHomepagePoweringTiles: React.FC<TArHomepagePoweringTilesProps> = () => {
  return (
    <ArContainer className={s.container} contentWrapperClassName={s.contentWrapper} full>
      <h2>Transform your placement and underwriting strategies</h2>
      <p>Artificial gives you the power to navigate a changing specialty risk transfer landscape.</p>
      <div className={s.tiles}>
        {TILES.map((item, i) => (
          <div key={i} className={s.tile}>
            {item.icon}
            <h3>{item.title}</h3>
            <p dangerouslySetInnerHTML={{ __html: parseRichText(item.subtitle) }} />
          </div>
        ))}
      </div>
    </ArContainer>
  )
}
