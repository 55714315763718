import * as s from './ArHomepagePlatformSelect.module.scss'

import { ArContainer } from 'src/components/ArContainer'
import { Link } from 'gatsby'
import { ROUTES } from 'src/config/routes'

import UWPlatformIcon from 'src/assets/images/uw-platform-icon.png'
import ContractBuilderIcon from 'src/assets/images/contract-builder-icon.png'

import React from 'react'
import { TArHomepagePlatformSelectProps } from './ArHomepagePlatformSelect.types'

export const ArHomepagePlatformSelect: React.FC<TArHomepagePlatformSelectProps> = () => {
  return (
    <ArContainer className={s.container} contentWrapperClassName={s.contentWrapper} full>
      <h2 className="pb-4">Our solutions</h2>
      <p className="lg:text-center pb-12">
      Our toolkit delivers precise, rapid and cost-effective solutions to the most complex challenges faced by brokers and carriers.
      </p>
      <div className={`grid lg:grid-cols-2 gap-12 w-full ${s.platforms}`}>
        <Link to={ROUTES.platform()} key="insurers">
          <div className="bg-white p-8 rounded-2xl w-full group hover:shadow-2xl cursor-pointer transition-all duration-300">
            <div className="w-56">
              <img src={UWPlatformIcon} alt="Artificial Partner Logos" className="w-1/2 scale-50 pb-10 object-cover" />
            </div>
            <h3>Smart Underwriting</h3>
            <p className="text-base">Our configurable platform enables carriers to deploy underwriting capital to lead and follow opportunities in the most effective way through digital underwriting.</p>
            <button className="text-black bg-white border font-medium border-gray-200 shadow-sm rounded-full px-5 py-1.5 mt-4 group-hover:text-red-500 transition duration-500">

              Explore<span className="ml-1.5 text-red-400">→</span>
            </button>
          </div>
        </Link>
        <Link to={ROUTES.brokers()} key="insurers">
          <div className="bg-white p-8 rounded-2xl w-full group hover:shadow-2xl cursor-pointer transition-all duration-300">
            <div className="w-56">
              <img
                src={ContractBuilderIcon}
                alt="Artificial Partner Logos"
                className="w-1/2 scale-50 pb-10 object-cover"
              />
            </div>
            <h3>Smart Placement</h3>
            <p>We remove the operational complexities of evolving placement strategies for brokers and empower them to make the most of their scale and leverage.</p>
            <button className="text-black bg-white border font-medium border-gray-200 shadow-sm rounded-full px-5 py-1.5 mt-4 group-hover:text-red-500 transition duration-500">
              Explore<span className="ml-1.5 text-red-400">→</span>
            </button>
          </div>
        </Link>
      </div>
    </ArContainer>
  )
}
