import * as s from './ArHomepageCustomerLove.module.scss'

import { ArContainer } from 'src/components/ArContainer'
import React from 'react'
import { TArHomepageCustomerLoveProps } from './ArHomepageCustomerLove.types'
import LogoBms from 'src/assets/svg/logo-bms.svg'

export const ArHomepageCustomerLove: React.FC<TArHomepageCustomerLoveProps> = () => {
  return (
    <ArContainer className={s.container} full>
      <h6>Loved by our customers</h6>
      <div className={s.quotation}>
        <h3>
          <span>
            <div>“</div>
          </span>
          Our focused collaborative relationship with Artificial enables us to continuously strive for ongoing enhancements and developments of the [BMS Group] platform.
          <span>
            <div>”</div>
          </span>
        </h3>
        <LogoBms aria-label="BMS logo" />
        <p className={s.footer}>
          <span>Adam Stafford</span> Chief Operations Officer, UK & International at BMS Group
        </p>
      </div>
    </ArContainer>
  )
}
